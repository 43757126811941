import React from 'react';

const Rag = () => {
  return (
    <iframe
      src="/second.html"
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="first"
    />
  );
};

export default Rag;