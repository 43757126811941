import React from 'react'

const Unique2 = () => {
  return (
    <iframe
    src="/unique.html"
    style={{ width: '100%', height: '100vh', border: 'none' }}
    title="first"
  />  )
}

export default Unique2